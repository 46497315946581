import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {
  Box,
  Switch,
  Text,
  Textarea,
  Group,
  createStyles,
  NumberInput,
} from '@mantine/core'
import { Button, Card } from 'components'

const RemoteConfig = () => {
  const { classes } = useStyles()
  const [remoteConfig, setRemoteConfig] = useState({
    message: '',
    maintenance: false,
    newSignup: false,
    newKyc: false,
    bankTransferStatus: true,
    instapayStatus: true,
    pesonetStatus: true,
    billsPayStatus: true,
    gamingCreditsStatus: true,
    loadStatus: true,
    remittanceCenterStatus: true,
    sendGloballyStatus: true,
    sendGloballyACHStatus: true,
    sendGloballyWireStatus: true,
    sendLightningStatus: true,
    transportationStatus: true,
    bitrefillStatus: true,
    scanStatus: true,
    lnurlPayStatus: true,
    lnurlWithdrawStatus: true,
    buyBtcStatus: true,
    buyBtcLightningStatus: true,
    buyBtcOnchainStatus: true,
    qrPhStatus: true,
    pinTimer: 1, // second/s
  })
  const [loading, setLoading] = useState(false)

  const handleRemoteConfigChange = (configName) => {
    const newRemoteConfig = {
      ...remoteConfig,
      [configName]: !remoteConfig[configName],
    }

    setRemoteConfig(newRemoteConfig)
    updateRemoteConfig(newRemoteConfig)
  }

  const fetchRemoteConfig = async () => {
    try {
      setLoading(true)
      const { data } = await axios.get('/api/v2/remote-config')

      if (Object.keys(data).length) {
        setRemoteConfig(data)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const updateRemoteConfig = async (newRemoteConfig) => {
    try {
      await axios.put('/api/v2/remote-config', {
        newRemoteConfig,
      })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchRemoteConfig()
  }, [])

  const handlePinTimer = (value) => {
    const newRemoteConfig = {
      ...remoteConfig,
      pinTimer: value,
    }
    setRemoteConfig(newRemoteConfig)
    updateRemoteConfig(newRemoteConfig)
  }

  return (
    <Card>
      {loading ? (
        <div className="loader" />
      ) : (
        <>
          <Item
            title="Maintenance"
            value={remoteConfig.maintenance}
            onChange={() => {
              handleRemoteConfigChange('maintenance')
            }}
          />

          <Item
            title="Force Migrate"
            value={remoteConfig.forceMigrate}
            onChange={() => {
              handleRemoteConfigChange('forceMigrate')
            }}
          />

          <Item
            title="Bank Transfer"
            value={remoteConfig.bankTransferStatus}
            onChange={() => {
              handleRemoteConfigChange('bankTransferStatus')
            }}
          />

          <Item
            title="Bank Transfer - Instapay"
            value={remoteConfig.instapayStatus}
            onChange={() => {
              handleRemoteConfigChange('instapayStatus')
            }}
          />

          <Item
            title="Bank Transfer - Pesonet"
            value={remoteConfig.pesonetStatus}
            onChange={() => {
              handleRemoteConfigChange('pesonetStatus')
            }}
          />

          <Item
            title="Shop - Bill Pay"
            value={remoteConfig.billsPayStatus}
            onChange={() => {
              handleRemoteConfigChange('billsPayStatus')
            }}
          />

          <Item
            title="Shop - Game Credit"
            value={remoteConfig.gamingCreditsStatus}
            onChange={() => {
              handleRemoteConfigChange('gamingCreditsStatus')
            }}
          />

          <Item
            title="Shop - Load"
            value={remoteConfig.loadStatus}
            onChange={() => {
              handleRemoteConfigChange('loadStatus')
            }}
          />

          <Item
            title="Shop - Transportation"
            value={remoteConfig.transportationStatus}
            onChange={() => {
              handleRemoteConfigChange('transportationStatus')
            }}
          />

          <Item
            title="Shop - Bitrefill"
            value={remoteConfig.bitrefillStatus}
            onChange={() => {
              handleRemoteConfigChange('bitrefillStatus')
            }}
          />

          <Item
            title="LNURL Pay"
            value={remoteConfig.lnurlPayStatus}
            onChange={() => {
              handleRemoteConfigChange('lnurlPayStatus')
            }}
          />

          <Item
            title="LNURL Withdraw"
            value={remoteConfig.lnurlWithdrawStatus}
            onChange={() => {
              handleRemoteConfigChange('lnurlWithdrawStatus')
            }}
          />

          <Item
            title="Remittance Center"
            value={remoteConfig.remittanceCenterStatus}
            onChange={() => {
              handleRemoteConfigChange('remittanceCenterStatus')
            }}
          />

          <Item
            title="Scan"
            value={remoteConfig.scanStatus}
            onChange={() => {
              handleRemoteConfigChange('scanStatus')
            }}
          />

          <Item
            title="Send Globally"
            value={remoteConfig.sendGloballyStatus}
            onChange={() => {
              handleRemoteConfigChange('sendGloballyStatus')
            }}
          />

          <Item
            title="Send Globally - ACH"
            value={remoteConfig.sendGloballyACHStatus}
            onChange={() => {
              handleRemoteConfigChange('sendGloballyACHStatus')
            }}
          />
          <Item
            title="Send Globally - Wire"
            value={remoteConfig.sendGloballyWireStatus}
            onChange={() => {
              handleRemoteConfigChange('sendGloballyWireStatus')
            }}
          />

          <Item
            title="Send Lightning"
            value={remoteConfig.sendLightningStatus}
            onChange={() => {
              handleRemoteConfigChange('sendLightningStatus')
            }}
          />

          <Item
            title="Buy Bitcoin"
            value={remoteConfig.buyBtcStatus}
            onChange={() => {
              handleRemoteConfigChange('buyBtcStatus')
            }}
          />

          <Item
            title="Buy Bitcoin - Lightning"
            value={remoteConfig.buyBtcLightningStatus}
            onChange={() => {
              handleRemoteConfigChange('buyBtcLightningStatus')
            }}
          />

          <Item
            title="Buy Bitcoin - Onchain"
            value={remoteConfig.buyBtcOnchainStatus}
            onChange={() => {
              handleRemoteConfigChange('buyBtcOnchainStatus')
            }}
          />

          <Item
            title="QR Ph"
            value={remoteConfig.qrPhStatus}
            onChange={() => {
              handleRemoteConfigChange('qrPhStatus')
            }}
          />

          <Box className={classes.header}>
            <Text size="lg" weight={600}>
              PIN Timer (seconds)
            </Text>
            <Group>
              <NumberInput
                variant="filled"
                placeholder="Seconds"
                value={remoteConfig.pinTimer}
                onChange={handlePinTimer}
                min={1}
                sx={{ flex: 1, width: 100 }}
              />
              <Button
                onClick={async () => {
                  setLoading(true)
                  await updateRemoteConfig(remoteConfig)
                  setLoading(false)
                }}
              >
                Save
              </Button>
            </Group>
          </Box>

          <Box className={classes.messageContainer}>
            <Textarea
              value={remoteConfig.message}
              onChange={(e) => {
                setRemoteConfig({
                  ...remoteConfig,
                  message: e.target.value,
                })
              }}
              mb="md"
              label="Message"
              placeholder="e.g Bank Transfer is not available..."
              minRows={10}
              maxRows={10}
            />
            <Button
              onClick={async () => {
                setLoading(true)
                await updateRemoteConfig(remoteConfig)
                setLoading(false)
              }}
            >
              Save
            </Button>
          </Box>
        </>
      )}
    </Card>
  )
}

const Item = ({ title, value, onChange }) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.header}>
      <Text size="lg" weight={600}>
        {title}
      </Text>
      <Switch checked={value} onChange={onChange} />
    </Box>
  )
}

const useStyles = createStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  messageContainer: {
    marginTop: 10,
  },
}))

export default RemoteConfig
